(function (App, $, window) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'ScrollReveal');

  /**
   * Permet de lancer la séquence d'animation du composant 2019/section-two-images.tpl
   * @param  object el component to animate
   */
  function animateThreeImages(el) {
    const $smallImage = el.find('.double-image__small');
    const $overImage = el.find('.double-image__large');
    const $aloneImage = el.find('.alone-image');

    const $smallImageMask = $smallImage.find('.animation-mask');
    const $overImageMask = $overImage.find('.animation-mask');
    const $aloneImageMask = $aloneImage.find('.animation-mask');

    const tl = new TimelineLite();
    const options = {
      scaleX: 0,
      transformOrigin: 'right',
      ease: window.Power2.easeOut,
    };

    tl.to($smallImageMask, 0.8, options)
      .to($smallImage, 0.4, { opacity: 1 }, '-=0.8')
      .to(
        $smallImageMask,
        0.4,
        {
          scaleX: 1,
          transformOrigin: 'left',
          ease: window.Power2.easeIn,
          onComplete() {
            $smallImage.hide();
          },
        },
        '-=0.2'
      )
      .to($aloneImageMask, 1, options, '-=0.4')
      .to($aloneImage, 0.5, { opacity: 1 }, '-=1')
      .to($overImageMask, 0.8, options, '-=0.6')
      .to(
        $overImage,
        0.4,
        {
          opacity: 1,
          onComplete() {
            const $blockCarac = $overImage.find('.block-carac__inner');

            if ($blockCarac) {
              $blockCarac.removeClass('block-carac__inner--close');
            }
          },
        },
        '-=0.8'
      );
  }

  /**
   * Gère le zoom dans l'image pleine largeur
   * @param  object el component to animate
   */
  function animateBackgroundImage(el) {
    const tl = new TimelineLite();
    const $mask = el.find('.animation-mask');
    const $background = el.find('.background-image');
    const $blockCarac = el.find('.block-carac__inner');

    tl.to($mask, 0.8, {
      scaleX: 0,
      transformOrigin: 'right',
      ease: window.Power2.easeOut,
    }).to(
      $background,
      2,
      {
        opacity: 1,
        scale: 1,
        ease: window.Power2.easeOut,
        onComplete() {
          if ($blockCarac) {
            $blockCarac.removeClass('block-carac__inner--close');
          }
        },
      },
      '-=0.5'
    );
  }

  /** @type {ScrollReveal} The main ScrollReveal instance */
  const reveal = new window.ScrollReveal({
    easing: 'cubic-bezier(0, 0.5, 0, 1)', // custom easing des familles
    duration: 1000,
    opacity: 0,
    mobile: false,
    viewFactor: 0.5,
    beforeReveal(el) {
      if ($(el).hasClass('section--two-images')) {
        animateThreeImages($(el));
      }

      if ($(el).hasClass('section--background-image')) {
        animateBackgroundImage($(el));
      }
    },
  });

  /** @type {Array} An array of selectors for the scroll reveal */
  const selectors = [
    // Legacy selectors
    '.lookbook-content__surtitle',
    '.lookbook-content__title',
    '.lookbook-content__content',
    '.lookbook-content__details',
    '.js-lookbook-reveal',
    // END Legacy selectors
    '.js-scroll-reveal',
  ];

  log(reveal, selectors);

  // We use the same test as scroll reveal to disable it on mobile
  if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    $('html').addClass('sr-is-mobile');
  }

  // We bind the scroll reveal to the App components' object
  App.components.scrollReveal = reveal;

  // We reveal all the items from the selectors array on window load
  $(window).on('load', function () {
    const $revealItems = $(selectors.join(', '));

    $revealItems.each(function (index, revealItem) {
      const $revealItem = $(revealItem);
      const options = {};

      if ($revealItem.data('delay')) {
        options.delay = $revealItem.data('delay');
      }

      options.scale = $revealItem.data('scale') === undefined ? 1 : 0;
      options.distance =
        $revealItem.data('distance') === undefined ? '200px' : $revealItem.data('distance');

      reveal.reveal(revealItem, options);
    });
  });
})(App, jQuery, window);
